import React from "react";

import EmailIcon from "@mui/icons-material/Email";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function Footer() {
  return (
    <div id="about" style={{ padding: "2.5% 0px" }}>
      <div
        className="footer"
        style={{
          display: "inline-block",
        }}
      >
        <a href="mailto:info@forevernow.world">
          <span>
            <EmailIcon />
          </span>
        </a>
        <a href="https://twitter.com/Forevernowworld">
          <span>
            <TwitterIcon />
          </span>
        </a>

        <a href="https://linked.com/">
          <span>
            <LinkedInIcon />
          </span>
        </a>
        <h4
          style={{
            display: "inline-block",
            textAlign: "center",
            marginTop: "10px",
          }}
          className="FooterTitle"
        >
          Copyright © 2022 Forevernow Pty Ltd
        </h4>
      </div>
    </div>
  );
}

export default Footer;
