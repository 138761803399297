import { Link } from "@mui/material";
import React from "react";
import logo from "../assets/logo.png";
// import logo from "../assets/logo1.png";

import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
// import introVideo from "../assets/Web-Intro.mp4";

// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Modal from "@mui/material/Modal";

function Navbar() {
  const [toggle, setToggle] = React.useState(false);

  return (
    <>
      <div className="outNav">
        <div className="navbar">
          <div className="logo">
            <img
              style={{ height: "100px", width: "280px" }}
              src={logo}
              alt="logo"
            />
          </div>
          {/* <Link className="link">
            <BasicModal />
          </Link> */}
          <div className="lg-menu menu menu-lg">
            <ul>
              <li>
                <Link className="link" href="#concept">
                  Vision
                </Link>
              </li>
              <li>
                <Link className="link" href="#services">
                  Services
                </Link>
              </li>
              <li>
                <Link className="link" href="#innovation">
                  Innovation
                </Link>
              </li>
              <li>
                <Link className="link" href="#NFT">
                  NFT
                </Link>
              </li>
              <li>
                <Link className="link" href="#journey">
                  Journey
                </Link>
              </li>
              <li>
                <Link className="link" href="#about">
                  About
                </Link>
              </li>
            </ul>
          </div>
          <div className="sm-menu menu menu-lg">
            <p onClick={() => setToggle(!toggle)}>
              {toggle ? (
                <MenuOpenIcon
                  style={{ fontSize: "3rem", marginLeft: "-60%" }}
                />
              ) : (
                <MenuIcon style={{ fontSize: "3rem", marginLeft: "-60%" }} />
              )}
            </p>
          </div>
        </div>
      </div>
      {toggle && (
        <div
          className="mid-nav"
          style={{ top: toggle && "10%", transition: "0.4s ease-in-out" }}
        >
          <Link className="link" href="#concept">
            <p>Concept</p>
          </Link>
          <Link className="link" href="#services">
            <p>Services</p>
          </Link>
          <Link className="link" href="#innovation">
            <p>Innovation</p>
          </Link>
          <Link className="link" href="#NFT">
            <p>NFT</p>
          </Link>
          <Link className="link" href="#journey">
            <p>Journey</p>
          </Link>
          <Link className="link" href="#about">
            <p>About</p>
          </Link>
        </div>
      )}
      {toggle && (
        <div
          className="sm-nav"
          style={{ top: toggle && "10%", transition: "0.4s ease-in-out" }}
        >
          <Link className="link" href="#mobileInspiration">
            <p>Concept</p>
          </Link>
          <Link className="link" href="#mobileServices">
            <p>Services</p>
          </Link>
          <Link className="link" href="#mobileInnovation">
            <p>Innovation</p>
          </Link>
          <Link className="link" href="#NFT" style={{ textDecoration: "none" }}>
            <p>NFT</p>
          </Link>
          <Link className="link" href="#mobileJourney">
            <p>Journey</p>
          </Link>
          <Link className="link" href="#mobileAbout">
            <p>About</p>
          </Link>
        </div>
      )}
    </>
  );
}

// const style = {
//   position: "absolute",
//   marginTop: "410px",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "1100px",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
// };

// function BasicModal() {
//   const [open, setOpen] = React.useState(true);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   useEffect(() => {
//     setInterval(() => {
//       setOpen(false);
//     }, 29000);
//   });

//   return (
//     <div>
//       <Button style={{ display: "none" }} onClick={handleOpen}>
//         Open modal
//       </Button>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <div>
//           <Box sx={style} className="introVideo">
//             <p onClick={handleClose} className="cross">
//               X
//             </p>
//             <video
//               id="vid"
//               width="100%"
//               height="100%"
//               muted={true}
//               loop={true}
//               autoPlay={true}
//               controls={true}
//             >
//               <source src={introVideo} type="video/mp4" />
//             </video>
//           </Box>
//         </div>
//       </Modal>
//     </div>
//   );
// }

export default Navbar;
