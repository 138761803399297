import React from "react";
import Grid from "@mui/material/Grid";
import image2 from "../assets/inspiration1.jpg";
import image3 from "../assets/inspiration4.png";
import image1 from "../assets/inspiration2.png";

function Inspiration() {
  const [details] = React.useState(0);

  console.log("details", details);
  return (
    <section className="mainSection">
      <div id="concept" className="common-mt">
        <Grid container spacing={2}>
          <Grid
            className="inspirationMain"
            item
            xl={6}
            ls={6}
            md={6}
            sm={12}
            xs={12}
          >
            <div className="Inspiration-main shape-main">
              <div className="inspirationShape shape-left">
                <img src={image1} alt="butterfly" id="inspiration1" />
              </div>
              <div className="inspirationShape shape-right">
                <img id="inspiration2" alt="soil" src={image2} />
                <br />
                <img src={image3} alt="people" />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xl={6}
            ls={6}
            md={6}
            sm={12}
            xs={12}
            className="inspirationText"
          >
            <h1 className="sectionTitle" style={{ textAlign: "center" }}>
              Our Inspiration
            </h1>
            <div
              className="option grid"
              style={{
                textAlign: "center",
                justifyContent: "center",
                display: "inline-block",
              }}
            >
              <p>
                We are inspired by our individual and collective journeys
                through life, and the desire to share our experiences with one
                another and across time for future generations.
              </p>
              <p>
                Creation and expression is what makes us human. Moments define
                our experiences.
              </p>
              <p>
                Transforming these moments into living memories that transcend
                time is what gets us out of bed in the morning.
              </p>
              <br></br>
              {/* <label className="modalButton" for="inspirationModal">
                Learn More...
              </label> */}
            </div>
          </Grid>
        </Grid>
      </div>
      <div class="mobileCard" id="mobileInspiration">
        <div class="mobileCardImage">
          <img src={image1} alt="user" />
        </div>
        <div class="mobileCardTitle">
          <p>Our Inspiration</p>
        </div>
        <div class="mobileCardSubtitle">
          <p>
            We are inspired by our individual and collective journeys through
            life, and the desire to share our experiences with one another and
            across time for future generations.
          </p>
          <p>
            Creation and expression is what makes us human. Moments define our
            experiences.
          </p>
          <p>
            Transforming these moments into living memories that transcend time
            is what gets us out of bed in the morning.
          </p>
          <br></br>
          {/* <label class="modalButton" for="inspirationModal">
            Learn More...
          </label> */}
        </div>
      </div>
      <input class="modalState" id="inspirationModal" type="checkbox" />{" "}
      <div class="modal">
        <label class="modalBG" for="inspirationModal"></label>
        <div class="modalInner">
          <label class="modalClose" for="inspirationModal"></label>
          <h2 className="sectionTitle">OUR INSPIRATION</h2>
          <p>Sorry! More information coming soon.</p>
          <br></br>
          <p></p>
          <br></br>
          <p></p>
          <br></br>
          <p></p>
          <p></p>
          <br></br>
          <p></p>
        </div>
      </div>
    </section>
  );
}

export default Inspiration;
