import "./App.css";
import Layout from "./component/Layout";
import Banner from "./component/Banner";
import Inspiration from "./component/Inspiration";
import Services from "./component/Services";
import Innovation from "./component/Innovation";
import Journey from "./component/Journey";
import About from "./component/About";
import Marketplace from "./component/Marketplace";
import MintingEngine from "./component/MintingEngine";

import Footer from "./component/Footer";
import ParticleBackground from "./component/ParticleBackground";
import ReactGA from "react-ga";

// might need to put this code in env
ReactGA.initialize("UA-220714940-1");

function App() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div className="App">
      <Layout>
        <ParticleBackground className="particles" />
        <Banner />
        <Inspiration />
        <Services />
        <Innovation />
        <MintingEngine />
        <Marketplace />
        <Journey />
        <About />
        <Footer />
      </Layout>
    </div>
  );
}

export default App;
