import React from "react";
import Grid from "@mui/material/Grid";

import image1 from "../assets/marketplace.png";

function Marketplace() {
  return (
    <section className="mainSection">
      <div id="innovation" className="common-mt">
        <Grid container spacing={2}>
          <Grid
            item
            xl={6}
            ls={6}
            md={6}
            sm={12}
            xs={12}
            className="inspirationText"
            style={{
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ padding: "0px 30px" }}>
              <h1 className="sectionTitle" style={{ textAlign: "center" }}>
                Forevernow NFTs
              </h1>
              <div
                className="option grid"
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  display: "inline-block",
                }}
              >
                <p>
                  Our NFT collections are first of kind and our hope is that
                  they are special enough to be kept and not flipped.
                </p>
                <p>
                  We create between 1 and 2 unique collections each year and
                  expect that they will be the collectibles of tomorrow.{" "}
                </p>
                <br></br>
                <form action="https://forevernow.today">
                  <button className="button">Explore Now</button>
                </form>
              </div>
            </div>
          </Grid>
          <Grid item xl={6} ls={6} md={6} sm={12} xs={12}>
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                margin: 0,
              }}
            >
              <img
                alt="NFT"
                style={{
                  width: "102%",
                  height: "102%",
                }}
                src={image1}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div class="mobileCard" id="mobileInnovation">
        <div class="mobileCardImage">
          <img src={image1} alt="user" />
        </div>
        <div class="mobileCardTitle">
          <p>Forevernow NFTs</p>
        </div>
        <div class="mobileCardSubtitle">
          <p>
            Our NFT collections are first of kind and our hope is that they are
            special enough to be kept and not flipped.
          </p>
          <p>
            We create between 1 and 2 unique collections each year and expect
            that they will be the collectibles of tomorrow.{" "}
          </p>
          <form action="https://forevernow.today">
            <button className="button">Explore Now</button>
          </form>
          <br></br>
        </div>
      </div>
    </section>
  );
}

export default Marketplace;
