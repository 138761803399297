import React from "react";
import Grid from "@mui/material/Grid";

import image1 from "../assets/About.png";
import image3 from "../assets/contactUs2.png";
import image6 from "../assets/about4.png";
import image2 from "../assets/lakupipu.png";
import image4 from "../assets/mrBaldy.png";
import image5 from "../assets/Simon.png";
import image7 from "../assets/Rhonda.png";

function About() {
  const [details] = React.useState(0);

  console.log("details", details);

  return (
    <section className="mainSection" style={{marginBottom:"-50px"}}>
      <div id="about" className="common-mt">
        <Grid container spacing={2}>
          <Grid
            item
            xl={6}
            ls={6}
            md={6}
            sm={12}
            xs={12}
            className="inspirationText option grid"
          >
            <h1 className=" sectionTitle" style={{ textAlign: "center" }}>
              About
            </h1>
            <p>
              {" "}
              Established in 2021, Forevernow is an Australian-based company
              developing human-centric use cases on the public ledger.
            </p>
            <h2
              className="inspirationheader sectionTitle"
              style={{ textAlign: "center", paddingTop: "20px" }}
            >
              Team
            </h2>
            <p>
              Our founders and development team have extensive experience across
              ICT business systems and a deep understanding of distributed
              ledger technology.
            </p>
            <div className="avatarContainer">
              <div className="avatar">
                <img
                  src={image5}
                  alt="founder"
                  style={{ background: "none" }}
                ></img>
                <p>Simon</p>
                <p id="avatarDescription">Co-Founder / Barista</p>
              </div>
              <div className="avatar">
                <img
                  src={image4}
                  alt="founder"
                  style={{
                    background: "none",
                    width: "100px",
                    height: "100px",
                  }}
                ></img>
                <p>John</p>
                <p id="avatarDescription">Co-Founder / Bar Tender</p>
              </div>
              <div className="avatar">
                <img
                  src={image2}
                  alt="developer"
                  style={{
                    background: "none",
                    width: "100px",
                    height: "100px",
                  }}
                ></img>
                <p>Karl</p>
                <p id="avatarDescription">Developer / Chef</p>
              </div>
            </div>
            <div className="avatarContainer">
              {/* add more members here */}
              <div className="avatar">
                <img
                  src={image7}
                  alt="developer"
                  style={{
                    background: "none",
                    width: "100px",
                    height: "100px",
                  }}
                ></img>
                <p>Rhonda</p>
                <p id="avatarDescription">Content / Connoisseur</p>
              </div>
              <div className="avatar">
                <label
                  className="teamButton"
                  style={{ display: "inline-block" }}
                  for="teamModal"
                >
                  See Whole Team
                </label>
              </div>
            </div>
            <h2
              className="inspirationheader sectionTitle"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              Contact Us
            </h2>
            <p>
              If you are looking to explore the benefits of distributed ledger
              or would just like to say hello, we would love to hear from you.
            </p>
            <p>
              You can reach the Forevernow team at{" "}
              <a href="mailto:info@forevernow.world">info@forevernow.world</a>
            </p>
          </Grid>
          <Grid
            className="inspirationMain aboutUsImage"
            item
            xl={6}
            ls={6}
            md={6}
            sm={12}
            xs={12}
            style={{
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <section>
              <div className="Inspiration-main shape-main">
                <div className="inspirationShape shape-left">
                  <img
                    src={image6}
                    id="about1"
                    style={{
                      bottom: "60px",
                      width: "300px",
                      height: "280px",
                      left: "100px",
                    }}
                    alt="hologram"
                  />
                </div>
                <div className="inspirationShape shape-right">
                  <img
                    id="about3"
                    src={image3}
                    style={{
                      background: "none",
                      right: "290px",
                      top: "420px",
                      width: "170px",
                      height: "140px",
                    }}
                    alt="communication"
                  />
                </div>
              </div>
              <div className="flipHex Inspiration-main shape-main">
                <div className="inspirationShape shape-left">
                  <img
                    src={image1}
                    id="about4"
                    style={{
                      background: "none",
                      width: "280px",
                      height: "240px",
                      top: "170px",
                      left: "50px",
                    }}
                    alt="Australia"
                  />
                </div>
              </div>
            </section>
          </Grid>
        </Grid>
      </div>
      <div class="mobileCard" id="mobileAbout">
        <div class="mobileCardImage">
          <img src={image6} alt="user" />
        </div>
        <div class="mobileCardTitle">
          <p>About</p>
        </div>
        <div class="mobileCardSubtitle">
          <p>
            {" "}
            Established in 2021, Forevernow is an Australian-based company
            developing human-centric use cases on the public ledger.
          </p>
          <br></br>

          <div class="mobileCardTitle2">
            <p>Team</p>
          </div>
          <p>
            Our founders and development team have extensive experience across
            ICT business systems and a deep understanding of distributed ledger
            technology.
          </p>
          <br></br>
          <div className="avatarContainer">
              <div className="avatar">
                <img
                  src={image5}
                  alt="founder"
                  style={{
                    background: "none",
                    width: "80px",
                    height: "80px",
                  }}
                ></img>
                <p>Simon</p>
                <p id="avatarDescription">Co-Founder / Barista</p>
              </div>
              <div className="avatar">
                <img
                  src={image4}
                  alt="founder"
                  style={{
                    background: "none",
                    width: "80px",
                    height: "80px",
                  }}
                ></img>
                <p>John</p>
                <p id="avatarDescription">Co-Founder / Bar Tender</p>
              </div>
              <div className="avatar">
                <img
                  src={image2}
                  alt="developer"
                  style={{
                    background: "none",
                    width: "80px",
                    height: "80px",
                  }}
                ></img>
                <p>Karl</p>
                <p id="avatarDescription">Developer / Chef</p>
              </div>
            </div>
            <div className="avatarContainer">
              {/* add more members here */}
              <div className="avatar">
                <img
                  src={image7}
                  alt="developer"
                  style={{
                    background: "none",
                    width: "80px",
                    height: "80px",
                  }}
                ></img>
                <p>Rhonda</p>
                <p id="avatarDescription">Content / Connoisseur</p>
              </div>
              <div className="avatar">
                <label
                  className="teamButton"
                  style={{ display: "inline-block" }}
                  for="teamModal"
                >
                  See Whole Team
                </label>
              </div>
            </div>
          <div class="mobileCardTitle2">
            <p>Contact Us</p>
          </div>
          <p>
            If you are looking to explore the benefits of distributed ledger or
            would just like to say hello, we would love to hear from you.
          </p>
          <p>
            You can reach the Forevernow team at{" "}
            <a
              href="mailto:info@forevernow.world"
              style={{ color: "rgb(127, 233, 233" }}
            >
              info@forevernow.world
            </a>
          </p>
          <br></br>
        </div>
        <div class="mobileCardImage">
          <img src={image1} alt="user" style={{width:"300px", height:"260px"}}/>
        </div>
      </div>
      <input class="modalState" id="teamModal" type="checkbox" />{" "}
            <div class="modal">
              <label class="modalBG" for="teamModal"></label>
              <div class="modalInner">
                <label class="modalClose" for="teamModal"></label>
                <h2 className="sectionTitle">THE TEAM</h2>
                <p>Sorry! More information coming soon.</p>
              </div>
            </div>
    </section>
  );
}

export default About;
