import React from "react";
import Grid from "@mui/material/Grid";

import image1 from "../assets/innovation6.png";

function MintingEngine() {
  return (
    <section className="mainSection" id="NFT">
      <div id="innovation" className="common-mt">
        <Grid container spacing={2}>
          <Grid
            item
            xl={6}
            ls={6}
            md={6}
            sm={12}
            xs={12}
            className="inspirationText"
            style={{
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ padding: "0px 30px" }}>
              <h1 className="sectionTitle" style={{ textAlign: "center" }}>
                Chronos
              </h1>
              <div
                className="option grid"
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  display: "inline-block",
                }}
              >
                <p>
                  We have designed a simple NFT generator and viewer that can be
                  used by anyone to create permanent forever assets. Don’t let
                  your moments be lost to time.
                </p>
                <p>Start generating your forever world today.</p>
                <br></br>
                <form action="https://www.forevernft.today/">
                  <button className="button">Create Now</button>
                </form>
              </div>
            </div>
          </Grid>
          <Grid item xl={6} ls={6} md={6} sm={12} xs={12}>
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                margin: 0,
              }}
            >
              <img
                alt="NFT"
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={image1}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div class="mobileCard" id="mobileInnovation">
        <div class="mobileCardImage">
          <img src={image1} alt="user" />
        </div>
        <div class="mobileCardTitle">
          <p>Chronos</p>
        </div>
        <div class="mobileCardSubtitle">
          <p>
            We have designed a simple NFT generator that can be used by anyone
            to create permanent forever assets. Don’t let your moments be lost
            to time.
          </p>
          <p>Start generating your forever world today.</p>
          <form action="https://www.forevernft.today">
            <button className="button">Create Now</button>
          </form>
          <br></br>
        </div>
      </div>
    </section>
  );
}

export default MintingEngine;
