import {} from "@mui/material";
import React from "react";
import image1 from "../assets/development.png";
import image2 from "../assets/partnerships.png";
import image3 from "../assets/planning.png";
import image4 from "../assets/pilot.png";
import image5 from "../assets/coreServices.png";
import image6 from "../assets/collab.png";
import image7 from "../assets/futureServices.png";
import image8 from "../assets/enhancement.png";
import image9 from "../assets/optimisation.png";
import image10 from "../assets/continuous.png";
import image11 from "../assets/journeyMobile.png";
import image12 from "../assets/marketplaceIcon.png";

function Journey() {
  return (
    <section className="mainSection">
      <section className="common-mt" id="journey">
        <h1
          className="sectionTitle"
          style={{ paddingBottom: "20px", textAlign: "cent" }}
        >
          Our Journey
        </h1>
        <div class="cards-list option">
          <div class="card 1" id="card1">
            <h3 class="card_title">2022</h3>
            <img src={image3} alt="planning" />
            <p>Research. Planning. Design.</p>
            <br></br>
            <img src={image2} alt="partnerships" />
            <p>Partnerships. Collaboration.</p>
            <br></br>
            <img src={image1} alt="microchip" />
            <p>Development. Integration. Testing.</p>
            <br></br>
            <img src={image7} alt="cycle" />
            <p>Our NFTs</p>
            <br></br>
            <img src={image4} alt="pilot" />
            <p>Pilot Program</p>
          </div>
          <div class="card 2" id="card2">
            <h3 class="card_title">2023</h3>
            <img src={image5} alt="go" />
            <p>Core Services Live</p>
            <br></br>
            <img src={image6} alt="people" />
            <p>Collaboration Expansion</p>
            <br></br>
            <img src={image7} alt="cycle" />
            <p>Future Services Development</p>
          </div>
          <div class="card 3" id="card3">
            <h3 class="card_title">2024 and Beyond</h3>
            <img src={image8} alt="go" />
            <p>Enhancement Releases</p>
            <br></br>
            <img src={image9} alt="people" />
            <p>Service Optimisation</p>
            <br></br>
            <img src={image10} alt="cycle" />
            <p>Continuous Service Improvement</p>
          </div>
        </div>
      </section>
      <div class="mobileCard" id="mobileJourney">
        <div class="mobileCardImage">
          <img
            src={image11}
            alt="user"
            style={{ background: "turquoise", opacity: "10%" }}
          />
        </div>
        <div class="mobileCardTitle">
          <p>Journey</p>
        </div>
        <div class="accordion">
          <div class="box2">
            <input type="checkbox" id="toggle1" class="togglez" />
            <label class="title" for="toggle1">
              2022
            </label>
            <div class="content">
              <br></br>
              <img src={image3} alt="planning" />
              <p>Research. Planning. Design.</p>
              <br></br>
              <img src={image2} alt="partnerships" />
              <p>Partnerships. Collaboration.</p>
              <br></br>
              <img src={image1} alt="microchip" />
              <p>Development. Integration. Testing.</p>
              <br></br>
              <img src={image7} alt="cycle" />
              <p>Our NFTs</p>
              <br></br>
              <img src={image4} alt="pilot" />
              <p>Pilot Program</p>
            </div>
          </div>

          <div class="box2">
            <input type="checkbox" id="toggle2" class="togglez" />
            <label class="title" for="toggle2">
              2023
            </label>
            <div class="content">
              <br></br>
              <img src={image5} alt="go" />
              <p>Core Services Live</p>
              <br></br>
              <img src={image6} alt="people" />
              <p>Collaboration Expansion</p>
              <br></br>
              <img src={image7} alt="cycle" />
              <p>Future Services Development</p>
            </div>
          </div>

          <div class="box2">
            <input type="checkbox" id="toggle3" class="togglez" />
            <label class="title" for="toggle3">
              2024 AND BEYOND
            </label>
            <div class="content">
              <br></br>
              <img src={image8} alt="go" />
              <p>Enhancement Releases</p>
              <br></br>
              <img src={image9} alt="people" />
              <p>Service Optimisation</p>
              <br></br>
              <img src={image10} alt="cycle" />
              <p>Continuous Service Improvement</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Journey;
