import React from "react";
import Grid from "@mui/material/Grid";

import image1 from "../assets/services-ai.png";
import image2 from "../assets/innovate2.png";

function Innovation() {
  return (
    <section className="mainSection">
      <div id="innovation" className="common-mt">
        <Grid container spacing={2}>
          <Grid
            item
            xl={6}
            ls={6}
            md={6}
            sm={12}
            xs={12}
            className="inspirationMain"
          >
            <section>
              <div style={{ display: "inline" }} className="shape-main">
                <div className="ImageTop Innovationleft shape-left">
                  <img
                    alt="NFT"
                    id="innovation1"
                    style={{ width: "45%", height: "50%", marginRight: "10px" }}
                    src={image1}
                  />
                </div>
                <div className="ImageBottom shape-left InnovationRight">
                  <img
                    alt="phone"
                    id="innovation2"
                    style={{
                      width: "65%",
                      height: "50%",
                      left: "60px",
                      top: "0px",
                    }}
                    src={image2}
                  />
                </div>
              </div>
            </section>
          </Grid>
          <Grid
            item
            xl={6}
            ls={6}
            md={6}
            sm={12}
            xs={12}
            className="inspirationText"
          >
            <h1 className="sectionTitle" style={{ textAlign: "center" }}>
              Innovation
            </h1>
            <div
              className="option grid"
              style={{
                textAlign: "center",
                justifyContent: "center",
                display: "inline-block",
              }}
            >
              <p>
                Together with leading industry partners and academia, we are
                exploring transforming uses for trust, immutability, security
                and permanence of the public ledger to create digital worlds
                that can be shared forever.
              </p>
              <p>
                Our solutions seek to improve all aspects of our lives and the
                health of our beautiful planet. By leveraging technology, we can
                replace harmful and polluting production methods and contribute
                to a cleaner, safer world for future generations.{" "}
              </p>
              <br></br>
              {/* <label className="modalButton" for="innovationModal">
                Learn More...
              </label> */}
            </div>
          </Grid>
        </Grid>
      </div>
      <div class="mobileCard" id="mobileInnovation">
        <div class="mobileCardImage">
          <img src={image1} alt="user" />
        </div>
        <div class="mobileCardTitle">
          <p>Innovation</p>
        </div>
        <div class="mobileCardSubtitle">
          <p>
            Together with leading industry partners and academia, we are
            exploring transforming uses for trust, immutability, security and
            permanence of the public ledger to create digital worlds that can be
            shared forever.
          </p>
          <p>
            Our solutions seek to improve all aspects of our lives and the
            health of our beautiful planet. By leveraging technology, we can
            replace harmful and polluting production methods and contribute to a
            cleaner, safer world for future generations.{" "}
          </p>
          <br></br>
          {/* <label className="modalButton" for="innovationModal">
            Learn More...
          </label> */}
        </div>
      </div>
      <input class="modalState" id="innovationModal" type="checkbox" />{" "}
      <div class="modal">
        <label class="modalBG" for="innovationModal"></label>
        <div class="modalInner">
          <label class="modalClose" for="innovationModal"></label>
          <h2 className="sectionTitle">INNOVATION</h2>
          <p>Sorry! More information coming soon.</p>
        </div>
      </div>
    </section>
  );
}

export default Innovation;
