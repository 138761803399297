import React from "react";
import Grid from "@mui/material/Grid";

import image1 from "../assets/servicesNew.png";

function Services() {
  return (
    <section className="mainSection">
      <div id="services" className="common-mt">
        <Grid container spacing={2}>
          <Grid
            item
            xl={6}
            ls={6}
            md={6}
            sm={12}
            xs={12}
            className="inspirationText"
          >
            <h1 className="sectionTitle" style={{ textAlign: "center" }}>
              Our Service
            </h1>
            <div
              className="option grid"
              style={{
                textAlign: "center",
                justifyContent: "center",
                display: "inline-block",
              }}
            >
              <p>
                Technology is as exciting as it is confusing for many people.
                Words like block-chain, DLT, NFT and cryptocurrency are enough
                to make anyone’s eye twitch.
              </p>
              <p>
                As well as demystifying the meta-verse, we focus on developing
                products and services that harness the benefits this evolving
                technology will bring to improving our lives.
              </p>
              <br></br>
              {/* <label class="modalButton" for="servicesModal">
                Learn More...
              </label> */}
            </div>
          </Grid>
          <Grid
            item
            xl={6}
            ls={6}
            md={6}
            sm={12}
            xs={12}
            className="Inspiration-main shape-main"
          >
            <div className="shape-main">
              <div className="shape-left services">
                <img id="serviceImage" src={image1} alt="serviceImage" />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div class="mobileCard" id="mobileServices">
        <div class="mobileCardImage">
          <img src={image1} alt="userImage" />
        </div>
        <div class="mobileCardTitle">
          <p>Our Services</p>
        </div>
        <div class="mobileCardSubtitle">
          <p>
            Technology is as exciting as it is confusing for many people. Words
            like block-chain, DLT, NFT and cryptocurrency are enough to make
            anyone’s eye twitch.
          </p>
          <p>
            As well as demystifying the meta-verse, we focus on developing
            products and services that harness the benefits this evolving
            technology will bring to improving our lives.
          </p>
          <br></br>
          {/* <label class="modalButton" for="servicesModal">
            Learn More...
          </label> */}
        </div>
      </div>
      <input class="modalState" id="servicesModal" type="checkbox" />{" "}
      <div class="modal">
        <label class="modalBG" for="servicesModal"></label>
        <div class="modalInner">
          <label class="modalClose" for="servicesModal"></label>
          <h2 className="sectionTitle">SERVICES</h2>
          <p>Sorry! More information coming soon.</p>
        </div>
      </div>
    </section>
  );
}
export default Services;
