import React, { useRef } from "react";
import Carousel from "react-elastic-carousel";

import two from "../assets/Banner-Image2.png";

const images = [
  {
    url: two,
    title: "Immoralising Monents",
  },
];

const totalPages = Math.ceil(images.length / 1);
let resetTimeout;

function Banner() {
  const carouselRef = useRef(null);
  var settings = {
    itemsToShow: 1,
    showArrows: false,
    enableAutoPlay: true,
    enableMouseSwipe: false,
    infinityLoop: true,
    autoPlaySpeed: 7500,
  };
  return (
    <>
      <Carousel
        id="carousel"
        style={{ marginBottom: "-70px", marginTop: "2%" }}
        ref={carouselRef}
        {...settings}
        onNextEnd={({ index }) => {
          clearTimeout(resetTimeout);
          if (index + 1 === totalPages) {
            resetTimeout = setTimeout(() => {
              carouselRef.current.goTo(0);
            }, 1500); // same time
          }
        }}
      >
        {images.map((image, index) => (
          <Item detils={image} />
          // <p>{image.title}</p>
        ))}
      </Carousel>

      <section id="carouselSection">
        <p
          className="carouselText"
          style={{
            height: "20px",
            objectFit: "scale-down",
            marginBottom: "300px",
            textAlign: "right",
          }}
        >
          IMMORTALISING MOMENTS...
        </p>
      </section>
    </>
  );
}

export default Banner;

function Item({ detils }) {
  return (
    <section id="sectionBanner">
      <div className="banner">
        <img src={detils.url} alt={detils.url} />
      </div>
      <br></br>
    </section>
  );
}
